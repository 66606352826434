<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
      </template>
      <template v-slot:searchBody>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('globalTrans.duty_calculator') }}</h4>
      </template>
      <template v-slot:body>
        <template>
                <b-row>
                  <b-col lg="8" md="8" sm="12">
                      <b-form-group label-cols-sm="5" label-for="duty_calculator">
                        <template v-slot:label>
                          {{ $t('globalTrans.duty_calculator') }}
                        </template>
                            <b-button variant="success" class="mr-2 btn-sm"
                              role="link"
                              @click="openInNewTab('http://bangladeshcustoms.gov.bd/trade_info/duty_calculator')">
                              {{ $t('globalTrans.calculate') }}
                            </b-button>
                      </b-form-group>
                  </b-col>
                </b-row>
        </template>
      </template>
    </body-card>
  </div>
</template>

<script>

export default {
  components: {
  },
  data () {
    return {
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    }
  },
  methods: {
    openInNewTab (url) {
      window.open(url, '_blank', 'noreferrer')
    }
  }
}
</script>
